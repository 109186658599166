<div
  class="tw-flex tw-max-w-md tw-flex-col tw-gap-4 tw-border tw-border-cloud tw-p-4"
>
  @switch (errorType) {
    @case (types.NOT_FOUND) {
      <ng-container [ngTemplateOutlet]="notFound"></ng-container>
    }
    @case (types.UNAUTHORIZED) {
      <ng-container [ngTemplateOutlet]="unauthorised"></ng-container>
    }
    @case (types.PERMISSION_DENIED) {
      <ng-container [ngTemplateOutlet]="permissionDenied"></ng-container>
    }
    @default {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
  }

  <div class="tw-flex tw-gap-2">
    <a [href]="mailTo" class="tw-btn tw-btn-primary">Contact support</a>
    <a [routerLink]="['/']" class="tw-btn">Homepage</a>
  </div>
  <div class="tw-font-mono tw-text-sm tw-text-night-3">
    @if (errorMessage) {
      <div>{{ errorMessage }}</div>
    }
    <div>{{ timestamp }}</div>
  </div>
</div>

<ng-template #error>
  <div class="tw-font-heading tw-text-3xl">Error</div>
  <div>You've hit an error which wasn't handled.</div>
  <div>
    Email&nbsp;<a [href]="mailTo" class="tw-link tw-break-all"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="px-2 py-1 tw-bg-cloud-4 tw-font-mono">{{ id }}</span>
    who will be able to help with the issue.
  </div>
</ng-template>

<ng-template #notFound>
  <div class="tw-font-heading tw-text-3xl">Not Found</div>
  <div>There is nothing on this page.</div>
  <div>
    Email&nbsp;<a [href]="mailTo" class="tw-link tw-break-all"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="px-2 py-1 tw-bg-cloud-4 tw-font-mono">{{ id }}</span>
    who might be able to find what you were looking for.
  </div>
</ng-template>

<ng-template #permissionDenied>
  <div class="tw-font-heading tw-text-3xl">Permission Denied</div>
  <div>You don't have access to this.</div>
  <div>
    Email&nbsp;<a [href]="mailTo" class="tw-link tw-break-all"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="px-2 py-1 tw-bg-cloud-4 tw-font-mono">{{ id }}</span>
    who might be able to give you permission to see it.
  </div>
</ng-template>

<ng-template #unauthorised>
  <div class="tw-font-heading tw-text-3xl">Unauthorised</div>
  <div>We were unable to log you in.</div>
  <div>
    Email&nbsp;<a [href]="mailTo" class="tw-link tw-break-all"
      >support&#64;humanmademachine.com</a
    >
    with code
    <span class="px-2 py-1 tw-bg-cloud-4 tw-font-mono">{{ id }}</span>
    who will be able to help get you access.
  </div>
</ng-template>
