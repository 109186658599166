import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ErrorService, getTransactionId } from '@sites/util-errors';

export enum ErrorType {
  UNKNOWN = 'unknown',
  NOT_FOUND = 'not-found',
  UNAUTHORIZED = 'unauthorized',
  PERMISSION_DENIED = 'permission-denied',
}

@Component({
  selector: 'dashboard-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class ErrorComponent implements OnInit {
  errorType: ErrorType = ErrorType.UNKNOWN;
  types = ErrorType;
  id = getTransactionId();
  timestamp = new Date().toUTCString();
  errorMessage = '';
  mailTo = '';

  constructor(
    private route: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.route.url.subscribe((url) => {
      const path = url[0]?.path || 'error';
      this.errorType = this.parsePath(path);
    });
  }

  ngOnInit() {
    this.errorMessage = this.errorService.getLastMessage();
    this.mailTo = this.generateMailTo();
  }

  private parsePath(path: string): ErrorType {
    switch (path) {
      case 'error':
        return ErrorType.UNKNOWN;
      case 'denied':
        return ErrorType.PERMISSION_DENIED;
      case 'unauthorized':
        return ErrorType.UNAUTHORIZED;
      default:
        return ErrorType.NOT_FOUND;
    }
  }

  private generateMailTo(): string {
    const email = 'support@humanmademachine.com';
    const subject = `Help with ${this.errorType} ${this.id} on ${this.timestamp}`;

    return `mailto:${email}?subject=${subject}`;
  }
}
